export const CancelIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_2564_23888)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4571 4.54289C19.8476 4.93342 19.8476 5.56658 19.4571 5.95711L5.95711 19.4571C5.56658 19.8476 4.93342 19.8476 4.54289 19.4571C4.15237 19.0666 4.15237 18.4334 4.54289 18.0429L18.0429 4.54289C18.4334 4.15237 19.0666 4.15237 19.4571 4.54289Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.54289 4.54289C4.93342 4.15237 5.56658 4.15237 5.95711 4.54289L19.4571 18.0429C19.8476 18.4334 19.8476 19.0666 19.4571 19.4571C19.0666 19.8476 18.4334 19.8476 18.0429 19.4571L4.54289 5.95711C4.15237 5.56658 4.15237 4.93342 4.54289 4.54289Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2564_23888">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const ArrowBottom: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
  >
    <path
      d="M1.70377 0.922852H11.2962C11.7258 0.922852 11.9554 1.42881 11.6725 1.7521L6.87629 7.23351C6.67708 7.46118 6.32292 7.46118 6.12371 7.23351L1.32748 1.7521C1.0446 1.42881 1.27419 0.922852 1.70377 0.922852Z"
      fill="black"
      stroke="#666666"
    />
  </svg>
);
export const QuestionMark: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <g clip-path="url(#clip0_2564_29872)">
      <path
        d="M12.5 2.27686C10.5716 2.27686 8.68657 2.84868 7.08319 3.92003C5.47982 4.99137 4.23013 6.51411 3.49218 8.29569C2.75422 10.0773 2.56114 12.0377 2.93735 13.929C3.31355 15.8203 4.24215 17.5576 5.60571 18.9211C6.96928 20.2847 8.70656 21.2133 10.5979 21.5895C12.4892 21.9657 14.4496 21.7726 16.2312 21.0347C18.0127 20.2967 19.5355 19.047 20.6068 17.4437C21.6782 15.8403 22.25 13.9552 22.25 12.0269C22.2473 9.44183 21.2192 6.96347 19.3913 5.13558C17.5634 3.30769 15.085 2.27959 12.5 2.27686ZM12.5 18.0269C12.2775 18.0269 12.06 17.9609 11.875 17.8373C11.69 17.7136 11.5458 17.5379 11.4606 17.3324C11.3755 17.1268 11.3532 16.9006 11.3966 16.6824C11.44 16.4642 11.5472 16.2637 11.7045 16.1064C11.8618 15.949 12.0623 15.8419 12.2805 15.7985C12.4988 15.7551 12.725 15.7773 12.9305 15.8625C13.1361 15.9476 13.3118 16.0918 13.4354 16.2768C13.559 16.4618 13.625 16.6794 13.625 16.9019C13.625 17.2002 13.5065 17.4864 13.2955 17.6973C13.0845 17.9083 12.7984 18.0269 12.5 18.0269ZM13.25 13.4594V13.5269C13.25 13.7258 13.171 13.9165 13.0303 14.0572C12.8897 14.1978 12.6989 14.2769 12.5 14.2769C12.3011 14.2769 12.1103 14.1978 11.9697 14.0572C11.829 13.9165 11.75 13.7258 11.75 13.5269V12.7769C11.75 12.5779 11.829 12.3872 11.9697 12.2465C12.1103 12.1059 12.3011 12.0269 12.5 12.0269C13.7403 12.0269 14.75 11.1831 14.75 10.1519C14.75 9.12061 13.7403 8.27686 12.5 8.27686C11.2597 8.27686 10.25 9.12061 10.25 10.1519V10.5269C10.25 10.7258 10.171 10.9165 10.0303 11.0572C9.88968 11.1978 9.69892 11.2769 9.5 11.2769C9.30109 11.2769 9.11033 11.1978 8.96967 11.0572C8.82902 10.9165 8.75 10.7258 8.75 10.5269V10.1519C8.75 8.29092 10.4319 6.77686 12.5 6.77686C14.5681 6.77686 16.25 8.29092 16.25 10.1519C16.25 11.7812 14.96 13.1453 13.25 13.4594Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2564_29872">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.5 0.0268555)"
        />
      </clipPath>
    </defs>
  </svg>
);
