const Text = () => {
  return (
    <div>
      <h1 className="text-2xl md:text-[32px] font-bold  text-center md:text-left">
        Migrate NFTs to Composable NFTs
      </h1>
      <p className="text-lg md:text-xl text-center md:text-left">
        Migrate your NFTs to Composable NFTs and use its advanced utilities
      </p>
      <div className="flex flex-col md:flex-row gap-3 md:gap-12 items-center">
        <a href="" target="blank">
          <span className="font-semibold text-primary-default">Learn more</span>
        </a>
      </div>
    </div>
  );
};

export default Text;
